import type { Theme } from '@emotion/react';
import type { PaperColorElevationProps } from '../PaperColorElevation';

import * as React from 'react';
import styled from '@emotion/styled';
import { Typography as MuiTypography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'next-i18next';
import { useEditorHandlers } from './@editor';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { PaperColorElevation } from '../PaperColorElevation';
import { useAppContext } from '../../contexts/AppContext';
import useLocalStorage from '../../utils/use-local-storage';

export type PaperColorElevationPropsOmit = Omit<PaperColorElevationProps, 'variant'>;
export interface AdvertisementSlotProps extends PaperColorElevationPropsOmit {
  className?: string;
  slotIdentifier: string;
  variant: keyof typeof AD_SIZES;
  alignment?: 'left' | 'right' | 'center';
  areaName?: string;
  configuration?: any;
  adType?: 'default' | 'pgs';
}

interface AdvertisementSlotStyleProps extends AdvertisementSlotProps {
  theme: Theme;
}

const AD_SIZES = {
  '120x600': { width: 120, height: 600 },
  '160x600': { width: 160, height: 600 },
  '300x600': { width: 300, height: 600 },
  '320x50': { width: 320, height: 50 },
  '320x100': { width: 320, height: 100 },
  '728x90': { width: 728, height: 90 },
  '728x112': { width: 728, height: 112 },
  '970x250': { width: 970, height: 250 },
  '336x280': { width: 336, height: 250 },
  '300x250': { width: 300, height: 250 },
  '300x170': { width: 300, height: 170 },
};

const Root = styled(PaperColorElevation)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(0),
}));

const Label = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: theme.spacing(2),
  padding: theme.spacing(0.25, 1),
  color: theme.theme_component_advertisement_slot_label_color || theme.palette.secondary.contrastText,
  backgroundColor: theme.theme_component_advertisement_slot_label_background_color || theme.palette.secondary.main,
  borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
  transform: 'translateY(-90%) scale(0.75)',
}));

const Inner = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

const SlotContainer = styled.div<AdvertisementSlotStyleProps>(({ variant }) => ({
  ...AD_SIZES[variant],
  overflow: 'hidden',
}));

export const AdvertisementSlot = (props: AdvertisementSlotProps) => {
  const { className, variant, slotIdentifier, alignment, configuration, adType = 'default', ...other } = props;

  const captionRef = React.useRef<HTMLInputElement | null>(null);
  const themeOverride = useTheme();
  const { t } = useTranslation('advertisement_slot');

  const { useConfig } = useAppContext();
  const { componentOptions } = useConfig();
  const subMode = componentOptions.component_project_subscription_mode;
  const [userToken] = useLocalStorage<string | undefined>('user-token', '');

  const hideAdvertisement = subMode && userToken;

  const renderAdContent = () => (
    adType === 'pgs' ? (
      <Inner
        dangerouslySetInnerHTML={{ __html: `<pgs-ad data-pg-ad-spot='${slotIdentifier}' style="width: 100%; height: 100%"></pgs-ad>` }}
        style={{ justifyContent: alignment, width: "100%", height: "100%" }}
      />
    ) : (
      <Inner id={slotIdentifier} style={{ justifyContent: alignment }} />
    )
  );

  return (
    <Root className={className} {...useEditorHandlers('Advertisement Slot', configuration)} {...other}>
      {hideAdvertisement ? null : (
        <>
          <Label>
            <Typography ref={captionRef} variant="caption" component="div">
              {t('label', 'Advertisement')}
            </Typography>
          </Label>
          <SlotContainer variant={variant} slotIdentifier={slotIdentifier} theme={themeOverride}>
            {renderAdContent()}
          </SlotContainer>
        </>
      )}
    </Root>
  );
};
