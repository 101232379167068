import type { CategoriesViewProps, GameMinimalProps, GameProps } from '../../types';

import * as React from 'react';
import styled from '@emotion/styled';
import dynamic from 'next/dynamic';
import { adsRequest } from '../../utils/ads-request';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'next-i18next';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { useInView } from 'react-intersection-observer';
import { Typography as MuiTypography, Button as MuiButton, useMediaQuery, withStyles } from '@mui/material';
import CardActionArea from '@mui/material/CardActionArea';
import { Link } from '../../components/Link';
import { Title } from '../../components/Title';
import { Card, CardSkeleton, CardAsset } from '../../components/Card';
import { useAppContext } from '../../contexts/AppContext';
import { PaperColorElevation } from '../../components/PaperColorElevation';
import { CardTypeEnum } from '../../types/enum';
import useLocalStorage from '../../utils/use-local-storage';
import { useCursorContext } from '../../contexts/CursorContext';
import { COLLECTIONS_B_BOTTOM, COLLECTIONS_B_MIDDLE, COLLECTIONS_B_TOP } from '../../types/static';
import useFlattenedGameData from '../../utils/useFlattenArray';

const AdvertisementSlot = dynamic<any>(
  () => import('../../components/AdvertisementSlot').then((mod) => mod.AdvertisementSlot),
  {
    ssr: false,
  }
);
const CollectionSection = dynamic<any>(
  () => import('../../components/CollectionSection').then((mod) => mod.CollectionSection),
  {
    ssr: false,
  }
);
const CarouselList = dynamic<any>(() => import('../../components/CarouselList').then((mod) => mod.CarouselList), {
  ssr: false,
  // suspense: true,
});
const CarouselThumbs = dynamic<any>(() => import('../../components/CarouselThumbs/CarouselThumbs'), {
  ssr: true,
  // suspense: true,
});

const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

const Button = React.forwardRef<HTMLButtonElement, any>((props, ref) =>
  useHighlightHandlers(MuiButton, { forwardedRef: ref, ...props })
);

interface RootProps extends React.HTMLAttributes<HTMLDivElement> {
  isfullscreen?: boolean;
}

interface HeaderSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  isfullscreen?: boolean;
}

const Root = styled('div')<RootProps>(({ theme, isfullscreen }) => ({
  display: 'grid',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  gap: theme.spacing(2),
  gridTemplateColumns: '100%',
  gridTemplateRows: 'auto',
  gridTemplateAreas: `
        'A'
        'D'
        'B'
        'C'
        'F'
        'E'
        'G'`,
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateAreas: `
        'A A'
        'D D'
        'B B'
        'C E'
        'F F'
        'G G'`,
  },
  [theme.breakpoints.up('lg')]: {
    gap: theme.spacing(4),
    // gridTemplate: `repeat(12, 1fr)`,
    // gridTemplateColumns: 'repeat(auto-fill, 1fr auto)',
    // gridTemplateRows: `${300 + theme.spacing(1)}px ${300 + theme.spacing(1)}px ${300 + theme.spacing(1)}px 250px 250px`,
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridTemplateAreas: `
      ${isfullscreen ? `
        'A A A A A A A A A A A A'
        'B B B B B B B B B B B B'
        'C C C C C C E E E E E E'
        'F F F F F F F F F F F F'
        'G G G G G G G G G G G G'` : 
        `'A A A A A A A A A A A A'
        'B B B B B B B B B B B B'
        'C C C C D D D D E E E E'
        'F F F F F F F F F F F F'
        'G G G G G G G G G G G G'`
      }
    `,
  },
}));

const HeaderSection = styled('div')<HeaderSectionProps>(({ theme, isfullscreen }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: 'auto',
  gridTemplateRows: 'auto',
  gridTemplateAreas: `
        'carousel'
        'skyscraper'
        'editor'
        'releases'`,
  width: '100%',
  padding: theme.spacing(0, 2),
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: `auto auto ${isfullscreen ? '0' : 300 + parseInt(theme.spacing(4).replace('px ', ''))}px`,
    gridTemplateAreas: `
        'carousel carousel skyscraper'
        'editor editor skyscraper'
        'releases releases releases'`,
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 2),
    gridTemplateColumns: `minmax(auto, auto) minmax(auto, 575px) ${isfullscreen ? '0' : 300 + parseInt(theme.spacing(4).replace('px ', ''))}px`,
    gridTemplateAreas: `
        'carousel editor skyscraper'
        'releases releases skyscraper'`,
  },
  [theme.breakpoints.up('xl')]: {
    gridTemplateColumns: `minmax(auto, auto) minmax(auto, 575px) ${isfullscreen ? '0' : 300 + parseInt(theme.spacing(4).replace('px ', ''))}px`,
  },
}));

const EditorsSection = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  gridTemplateRows: 'auto',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: `repeat(${theme.theme_component_number_of_popular_games_to_show || 4}, minmax(0, 1fr))`,
  },
}));


const FeaturedListSection = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  gridTemplateRows: '1fr',
  width: '100%',
  padding: theme.spacing(2, 0, 0),
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(5, minmax(0, 1fr))',
  },
}));

const MiddleSectionLeft = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
  gridTemplateRows: 'auto',
  padding: theme.spacing(2),
}));

const MiddleSectionRight = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
  gridTemplateRows: 'auto',
  padding: theme.spacing(2),
}));

const Box = styled(PaperColorElevation)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[0],
}));

const Box1 = styled(Box)(() => ({
  gridArea: 'A',
  borderRadius: 0,
  // backgroundColor: '#383838',

  // Very important, if left out, SwiperJS will break.
  // https://github.com/nolimits4web/swiper/issues/2914#issuecomment-787610135
  // overflow: 'hidden',
}));

const Box2 = styled(Box)(({ theme }) => ({
  gridArea: 'B',
  borderRadius: 0,
  marginTop: theme.spacing(-1.25), // Considering title top padding.
  // backgroundColor: '#e74c3c',

  // Very important, if left out, SwiperJS will break.
  // https://github.com/nolimits4web/swiper/issues/2914#issuecomment-787610135
  overflow: 'hidden',
}));

const Box3 = styled(Box)(({ theme }) => ({
  gridArea: 'C',
  justifyContent: 'normal',
  alignItems: 'normal',
  flexDirection: 'column',
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  // backgroundColor: '#16a085',
  [theme.breakpoints.up('md')]: {
    marginRight: 0,
  },
}));

const Box4 = styled(Box)(({ theme }) => ({
  gridArea: 'D',
  // backgroundColor: '#2ecc71',
  // margin: theme.spacing(0, 2),
  margin: 0,
  borderRadius: 0,
  padding: theme.spacing(2, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    borderRadius: theme.shape.borderRadius,
  },
  [theme.breakpoints.up('md')]: {
    margin: 0,
  },
}));

const Box5 = styled(Box)(({ theme }) => ({
  gridArea: 'E',
  justifyContent: 'normal',
  alignItems: 'normal',
  flexDirection: 'column',
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  // backgroundColor: '#3298db',
  [theme.breakpoints.up('md')]: {
    marginLeft: 0,
  },
}));

const Box6 = styled(Box)(({ theme }) => ({
  gridArea: 'F',
  borderRadius: 0,
  marginTop: theme.spacing(-1.25), // Considering title top padding.
  marginBottom: theme.spacing(-1.25), // Considering title top padding.
  // backgroundColor: '#9b59b6',

  // Very important, if left out, SwiperJS will break.
  // https://github.com/nolimits4web/swiper/issues/2914#issuecomment-787610135
  overflow: 'hidden',
}));

const Box7 = styled(Box)(({ theme }) => ({
  gridArea: 'G',
  // backgroundColor: '#3298db',
  padding: theme.spacing(2),
  borderRadius: 0,
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(2, 0),
  },
}));

const Box1a = styled(Box)(({ theme }) => ({
  gridArea: 'carousel',

  flexDirection: 'column',
  height: '100%',
  backgroundColor: theme.palette.grey['900'],

  [theme.breakpoints.up('lg')]: {
    maxHeight: 480, // Same as box2a
  },
  [theme.breakpoints.down('xl')]: {
    '& > div:first-of-type': {
      aspectRatio: '16/9',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    '& > div:last-of-type': {
      maxHeight: '20%',
    },
  },
  [theme.breakpoints.up('xl')]: {
    flexDirection: 'row',
    '& > div:last-of-type': {
      maxWidth: '20%',
      maxHeight: '100%',
      height: '100%',
    },
  },
  // backgroundColor: '#2ecc71',
  // [theme.breakpoints.only('lg')]: {
  //   padding: theme.spacing(2),
  // },

  // Very important, if left out, SwiperJS will break.
  // https://github.com/nolimits4web/swiper/issues/2914#issuecomment-787610135
  overflow: 'hidden',
}));

const Box2a = styled(Box)(({ theme }) => ({
  gridArea: 'editor',
  justifyContent: 'normal',
  alignItems: 'normal',
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    maxHeight: 480, // Same as box1a grid
  },
  // backgroundColor: '#3298db',
}));

const Box3a = styled(Box)(({ theme }) => ({
  gridArea: 'skyscraper',
  // backgroundColor: '#9b59b6',
  padding: theme.spacing(2),
  maxHeight: `calc(600px + ${theme.spacing(4)})`,
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(2, 0),
    margin: theme.spacing(0, -2),
    borderRadius: 0,
  },
}));

const Box4a = styled(Box)(({ theme }) => ({
  gridArea: 'releases',
  justifyContent: 'flex-end',
  alignItems: 'normal',
  flexDirection: 'column',
  background: 'none',
  marginTop: theme.spacing(-1.25), // Considering title top padding.
  // backgroundColor: '#e67e22',
}));

const CardPaperColorElevationFlat = styled(PaperColorElevation)(({ theme }) => ({
  boxShadow: theme.shadows[0],
  overflow: 'hidden',
})) as typeof PaperColorElevation;

const CardActionAreaFlat = styled(CardActionArea)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
})) as typeof CardActionArea;

const CardAssetFlat = styled(CardAsset)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  marginBottom: 0,
  overflow: 'hidden',
})) as typeof CardAsset;

const CardContentFlat = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: theme.spacing(0.25),
  paddingBottom: theme.spacing(0.25),
  maxWidth: `calc(100% - ${theme.spacing(4)})`,
}));

const CardTypographyFlat = styled(Typography)(() => ({
  width: '100%',
  height: 'fit-content',
  display: 'inline-block',
}));

const CardSkeletonStyled = styled(CardSkeleton)(({ theme }) => ({
  boxShadow: theme.shadows[0],
  aspectRatio: '16/9',
}));

const CardSkeletonGrid = styled(CardSkeleton)(({ theme }) => ({
  boxShadow: theme.shadows[0],
  aspectRatio: '4/3',
}));

const AdvertisementSlotStyled = styled(({...rest}: any) => {
  return <AdvertisementSlot {...rest} />;
})(({ theme }) => ({
  boxShadow: theme.shadows[0],
}));

const CardStyled = styled(Card)(({ theme, template, hoverEffect, cardBorder }: any) => ({
  width: '100%',
  // height: '100%',
  backgroundColor: theme.theme_component_card_background_color || theme.palette.background.paper,
  boxShadow: theme.theme_component_card_box_shadow || theme.shadows[3],
  borderRadius: template === 'big' ? 0 : `${theme.theme_component_card_border_radius}px` || theme.shape.borderRadius,
  border: cardBorder || `${theme.theme_component_card_content_border}px` || 'none',
  borderStyle: theme.theme_component_card_content_border ? 'solid' : 'none',
  borderColor: theme.theme_component_card_content_border ? theme.theme_component_card_content_border_color : 'none',
  transition: `transform ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,
  '&:hover': hoverEffect && {
    transform: 'scale3d(1.05, 1.05, 1)',
  },
}));

const AdvertisementSlotBottom = (variant: any) => {
  return (
    <Box7 elevation={0}>
      <AdvertisementSlotStyled
        slotIdentifier="idhb-bottom-970"
        variant={variant}
        elevation={1}
        configuration={COLLECTIONS_B_TOP}
      />
    </Box7>
  )
}

const AdvertisementSlotMiddle = () => {
  return (
    <Box4 className='testbox4' elevation={1}>
      <AdvertisementSlotStyled slotIdentifier="idhb-middle-336" variant="336x280" elevation={2} configuration={COLLECTIONS_B_MIDDLE} />
    </Box4>
  )
}

const AdvertisementSlotTop = (variant: any) => {
  return (
    <Box3a className="testttt123" elevation={1}>
      <AdvertisementSlotStyled
        slotIdentifier="idhb-right-300_1"
        variant={variant}
        elevation={2}
        configuration={COLLECTIONS_B_BOTTOM}
      />
    </Box3a>
  )
}

export const CollectionsB = (props: CategoriesViewProps) => {
  const { collections } = props;
  const { t } = useTranslation('collections_b');
  const { ref, inView } = useInView();
  const appContext = useAppContext();
  const { enabled } = useCursorContext();
  const { assetUrl, componentOptions } = appContext.useConfig();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const isBetweenMdAndLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const { panel } = useCursorContext();

  const buttonMiddleLeftRef = React.useRef<HTMLButtonElement | null>(null);
  const buttonMiddleRightRef = React.useRef<HTMLButtonElement | null>(null);
  const editorCardRefs = React.useRef<React.Ref<HTMLAnchorElement> | []>([]);
  const middleLeftCardRefs = React.useRef<React.Ref<HTMLAnchorElement> | []>([]);
  const middleRightCardRefs = React.useRef<React.Ref<HTMLAnchorElement> | []>([]);

  // Todo: Fix editorCardsRefs.current typescript problem.
  // @ts-ignore
  editorCardRefs.current = [...Array(12)].map((game, index: number) => {
    // @ts-ignore
    return editorCardRefs.current[index] ?? React.createRef();
  });

  // Todo: Fix middleLeftCardRefs.current typescript problem.
  // @ts-ignore
  middleLeftCardRefs.current = [...Array(12)].map((game, index: number) => {
    // @ts-ignore
    return middleLeftCardRefs.current[index] ?? React.createRef();
  });

  // Todo: Fix middleRightCardRefs.current typescript problem.
  // @ts-ignore
  middleRightCardRefs.current = [...Array(12)].map((game, index: number) => {
    // @ts-ignore
    return middleRightCardRefs.current[index] ?? React.createRef();
  });

  const obj: Record<string, boolean> = {
    'idhb-right-300_1': !componentOptions.component_right_ads_disable || !componentOptions.component_right_ads_tablet_disable || !componentOptions.component_right_ads_mobile_disable,
    'idhb-middle-336': !componentOptions.component_middle_center_ads_disable || !componentOptions.component_middle_center_ads_tablet_disable || !componentOptions.component_middle_center_ads_mobile_disable,
    'idhb-bottom-970': !componentOptions.component_bottom_ads_disable || !componentOptions.component_bottom_ads_tablet_disable || !componentOptions.component_bottom_ads_mobile_disable
  };

  React.useEffect(() => {
    const adsList = Object.keys(obj).filter(key => obj[key]);
    adsRequest(adsList);
  }, []);

  const cardType = componentOptions?.component_game_carousel_card_type;

  const [lastPlayedGames] = useLocalStorage<GameProps[] | undefined>('lastplayed:current', []);
  const showLastPlayedCarousel = componentOptions?.component_homepage_show_last_played_carousel;
  const recommendedCollection = collections.find((c) => c.code.includes('recommended')) || { games: [] };
  const homeCollections = collections?.filter((c) => !c.code.includes('recommended'));

  const flattenedData = useFlattenedGameData(lastPlayedGames || []);

  const headerSectionNotAds = () => {
    if(panel) return false;
      return !componentOptions.component_ads_disabled ? lg ? componentOptions.component_right_ads_disable : isBetweenMdAndLg ? componentOptions.component_right_ads_tablet_disable : componentOptions.component_right_ads_mobile_disable : componentOptions.component_ads_disabled
  }

  const rootSectionNotAds = () => {
    if(panel) return false;
      return !componentOptions.component_ads_disabled ? lg ? componentOptions.component_middle_center_ads_disable : isBetweenMdAndLg ? componentOptions.component_middle_center_ads_tablet_disable : componentOptions.component_middle_center_ads_mobile_disable : componentOptions.component_ads_disabled
  }

  return (
    <Root ref={ref} isfullscreen={rootSectionNotAds()}>
      <Box1 elevation={0}>
        <HeaderSection className="header-section" isfullscreen={headerSectionNotAds()}>
          <Box1a elevation={1}>
            <CarouselThumbs
              items={
                showLastPlayedCarousel && flattenedData && flattenedData.length > 0
                  ? [
                      ...flattenedData,
                      ...recommendedCollection.games.map((game) => ({
                        ...game,
                        trend: true,
                      })),
                    ]
                  : [...Array(4)].map((slot, index) => homeCollections[0]?.games?.[index])
              }
            />
          </Box1a>
          <Box2a elevation={1}>
            <Title className='birasitest' component="h1" variant="h2" align="left" style={{ paddingLeft: "16px", borderBottomWidth: 1}}>
              <Link
                href={`/${homeCollections[1]?.type}/${homeCollections[1]?.slug}`}
                title={homeCollections[1]?.name || undefined}
                underline="hover"
              >
                {homeCollections[1]?.name || 'Popular games'}
              </Link>
            </Title>
            <EditorsSection>
              {[...Array(homeCollections[1]?.games?.length > 12 ? 12 : homeCollections[1]?.games?.length)]
                .map((slot, index) => homeCollections[1]?.games?.[index])
                .map((item: GameMinimalProps, index) =>
                  item ? (
                    <CardPaperColorElevationFlat key={index} elevation={1}>
                      <CardActionAreaFlat component={Link} href={`${enabled ? '#' : `/game/${item.slug}`}`} title={item.name || undefined}>
                        <CardAssetFlat
                          src={`${assetUrl}${item.images.cover16x9 || item.images.cover}/`}
                          alt={item.name || 'Cover'}
                          width={componentOptions.component_card_width || 512}
                          height={componentOptions.component_card_height || 288}
                          layout="responsive"
                          sizes="(min-width:1200px) 8.333vw, (min-width:900px) 16.333vw, (min-width:600px) 25vw, 50vw"
                        />
                        <CardContentFlat>
                          <CardTypographyFlat
                            // @ts-ignore
                            ref={editorCardRefs.current[index]}
                            variant="caption"
                            component="span"
                            noWrap
                          >
                            {item.name}
                          </CardTypographyFlat>
                        </CardContentFlat>
                      </CardActionAreaFlat>
                    </CardPaperColorElevationFlat>
                  ) : (
                    <CardSkeletonGrid key={index} elevation={2} />
                  )
                )}
            </EditorsSection>
          </Box2a>
          {panel ? (
            <AdvertisementSlotTop variant={md ? '300x600' : '320x50'} />
          ) : (
            <>
              {!componentOptions.component_ads_disabled && (
                <>
                  {(lg && !componentOptions.component_right_ads_disable) && (
                    <AdvertisementSlotTop variant={md ? '300x600' : '320x50'} />
                  )}
                  {(isBetweenMdAndLg && !componentOptions.component_right_ads_tablet_disable) && (
                    <AdvertisementSlotTop variant={md ? '300x600' : '320x50'} />
                  )}
                  {(mdDown && !componentOptions.component_right_ads_mobile_disable) && (
                    <AdvertisementSlotTop variant={md ? '300x600' : '320x50'} />
                  )}
                </>
              )}
            </>
          )}
          <Box4a elevation={0}>
            <Title component="h2" variant="h2" align="left" disableGutters style={{ paddingLeft: "16px", borderBottomWidth: 1}}>
              <Link
                href={`/${homeCollections[2]?.type}/${homeCollections[2]?.slug}`}
                title={homeCollections[2]?.name || undefined}
                underline="hover"
              >
                {homeCollections[2]?.name || 'New releases'}
              </Link>
            </Title>
            <FeaturedListSection>
              {[...Array(mdDown ? 6 : 5)]
                .map((slot, index) => homeCollections[2]?.games?.[index])
                .map((item: GameMinimalProps, index) =>
                  item?.id ? (
                    <CardStyled
                      key={index}
                      href={`/game/${item.slug}`}
                      src={`${assetUrl}${item.images.cover16x9 || item.images.cover}/`}
                      alt={item.name || 'Cover'}
                      template={cardType || CardTypeEnum.DEFAULT}
                      title={item.name || undefined}
                      width={componentOptions.component_card_width || 512}
                      height={componentOptions.component_card_height || 288}
                      layout="responsive"
                      sizes="(min-width:900px) 14vw, (min-width:600px) 33.333vw, 50vw"
                      typographyOptions={{
                        align: 'left',
                      }}
                    />
                  ) : (
                    <CardSkeletonStyled key={index} elevation={2} />
                  )
                )}
            </FeaturedListSection>
          </Box4a>
        </HeaderSection>
      </Box1>
      <Box2 elevation={0}>
        {inView && (
          <CollectionSection>
            <Title component="h2" variant="h2" align="left" style={{ paddingLeft: "16px", borderBottomWidth: 1}}>
              <Link
                href={`/${homeCollections[3]?.type}/${homeCollections[3]?.slug}`}
                title={homeCollections[3]?.name || undefined}
                underline="hover"
              >
                {homeCollections[3]?.name || 'Puzzle'}
              </Link>
            </Title>
            <CarouselList
              padding={theme.spacing(2)}
              items={[...Array(12)].map((slot, index) => homeCollections[3]?.games?.[index])}
              cardOptions={{
                width: componentOptions.component_card_width || 512,
                height: componentOptions.component_card_height || 288,
                sizes: '(min-width:1200px) 16.666vw, (min-width:900px) 25vw, (min-width:600px) 33.333vw, 50vw',
                layout: 'responsive',

                template: cardType || CardTypeEnum.EXTENSIVE,
                typographyOptions: {
                  align: 'left',
                },
              }}
            />
          </CollectionSection>
        )}
      </Box2>
      <Box3 elevation={1}>
        <Title component="h2" variant="h2" align="left" style={{ paddingLeft: "16px", borderBottomWidth: 1}}>
          <Link
            href={`/${homeCollections[4]?.type}/${homeCollections[4]?.slug}`}
            title={homeCollections[4]?.name || undefined}
            underline="hover"
          >
            {collections[4]?.name || 'Simulation'}
          </Link>
        </Title>
        <MiddleSectionLeft>
          {[...Array(6)]
            .map((slot, index) => homeCollections[4]?.games?.[index])
            .map((item: GameMinimalProps, index) =>
              item ? (
                <CardPaperColorElevationFlat key={index} elevation={2}>
                  <CardActionAreaFlat component={Link} href={`/game/${item.slug}`} title={item.name || undefined}>
                    <CardAssetFlat
                      src={`${assetUrl}${item.images.cover16x9 || item.images.cover}/`}
                      alt={item.name || 'Cover'}
                      width={componentOptions.component_card_width || 512}
                      height={componentOptions.component_card_height || 288}
                      layout="responsive"
                      sizes="(min-width:1200px) 8.333vw, (min-width:900px) 16.666vw, (min-width:600px) 33.333vw, 50vw"
                    />
                    <CardContentFlat>
                      <CardTypographyFlat
                        // @ts-ignore
                        ref={middleRightCardRefs.current[index]}
                        variant="caption"
                        component="span"
                        noWrap
                      >
                        {item.name}
                      </CardTypographyFlat>
                    </CardContentFlat>
                  </CardActionAreaFlat>
                </CardPaperColorElevationFlat>
              ) : (
                <CardSkeletonGrid key={index} elevation={2} />
              )
            )}
        </MiddleSectionLeft>
        {homeCollections[4]?.type && (
          <Button
            ref={buttonMiddleLeftRef}
            color="primary"
            variant="contained"
            disableElevation={true}
            sx={{
              marginTop: 'auto',
              borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
            }}
            // Can't use href to send an object; https://github.com/mui/material-ui/issues/24106
            component={Link}
            href={`/${homeCollections[4]?.type}/${homeCollections[4]?.slug}`}
            title={homeCollections[4]?.name || undefined}
          >
            {t('view_all_label', 'View all')}
          </Button>
        )}
      </Box3>
      {panel ? (<AdvertisementSlotMiddle />) : (
        <>
          {!componentOptions.component_ads_disabled && (
            <>
              {(lg && !componentOptions.component_middle_center_ads_disable) && (
                <AdvertisementSlotMiddle />
              )}
              {(isBetweenMdAndLg && !componentOptions.component_middle_center_ads_tablet_disable) && (
                <AdvertisementSlotMiddle />
              )}
              {(mdDown && !componentOptions.component_middle_center_ads_mobile_disable) && (
                <AdvertisementSlotMiddle />
              )}
            </>
          )}
        </>
      )}
      
      <Box5 elevation={1}>
        <Title component="h2" variant="h2" align="left" style={{ paddingLeft: "16px", borderBottomWidth: 1}}>
          <Link
            href={`/${homeCollections[5]?.type}/${homeCollections[5]?.slug}`}
            title={homeCollections[5]?.name || undefined}
            underline="hover"
          >
            {homeCollections[5]?.name || 'Hypercasual'}
          </Link>
        </Title>
        <MiddleSectionRight>
          {[...Array(6)]
            .map((slot, index) => homeCollections[5]?.games?.[index])
            .map((item: GameMinimalProps, index) =>
              item ? (
                <CardPaperColorElevationFlat key={index} elevation={2}>
                  <CardActionAreaFlat
                    key={index}
                    component={Link}
                    href={`/game/${item.slug}`}
                    title={item.name || undefined}
                  >
                    <CardAssetFlat
                      src={`${assetUrl}${item.images.cover16x9 || item.images.cover}/`}
                      alt={item.name || 'Cover'}
                      width={componentOptions.component_card_width || 512}
                      height={componentOptions.component_card_height || 288}
                      layout="responsive"
                      sizes="(min-width:1200px) 8.333vw, (min-width:900px) 16.666vw, (min-width:600px) 33.333vw, 50vw"
                    />
                    <CardContentFlat>
                      <CardTypographyFlat
                        // @ts-ignore
                        ref={middleLeftCardRefs.current[index]}
                        variant="caption"
                        component="span"
                        noWrap
                      >
                        {item.name}
                      </CardTypographyFlat>
                    </CardContentFlat>
                  </CardActionAreaFlat>
                </CardPaperColorElevationFlat>
              ) : (
                <CardSkeletonGrid key={index} elevation={2} />
              )
            )}
        </MiddleSectionRight>
        {homeCollections[5]?.type && (
          <Button
            ref={buttonMiddleRightRef}
            color="primary"
            variant="contained"
            disableElevation={true}
            sx={{
              marginTop: 'auto',
              borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
            }}
            // Can't use href to send an object; https://github.com/mui/material-ui/issues/24106
            component={Link}
            href={`/${homeCollections[5]?.type}/${homeCollections[5]?.slug}`}
            title={homeCollections[5]?.name || undefined}
          >
            {t('view_all_label', 'View all')}
          </Button>
        )}
      </Box5>
      <Box6 elevation={0}>
        {inView && (
          <CollectionSection>
            <Title component="h2" variant="h2" align="left" style={{ paddingLeft: "16px", borderBottomWidth: 1}}>
              <Link
                href={`/${homeCollections[6]?.type}/${homeCollections[6]?.slug}`}
                title={homeCollections[6]?.name || undefined}
                underline="hover"
              >
                {homeCollections[6]?.name || 'Multiplayer'}
              </Link>
            </Title>
            <CarouselList
              padding={theme.spacing(2)}
              items={[...Array(12)].map((slot, index) => homeCollections[6]?.games?.[index])}
              cardOptions={{
                width: componentOptions.component_card_width || 512,
                height: componentOptions.component_card_height || 288,
                sizes: '(min-width:1200px) 16.666vw, (min-width:900px) 25vw, (min-width:600px) 33.333vw, 50vw',
                layout: 'responsive',
                typographyOptions: {
                  align: 'left',
                },
              }}
            />
          </CollectionSection>
        )}
      </Box6>
      {panel ? (
        <AdvertisementSlotBottom variant={lg ? '970x250' : md ? '728x90' : '320x50'} />
      ) : (
        <>
          {!componentOptions.component_ads_disabled && (
            <>
              {(lg && !componentOptions.component_bottom_ads_disable) && (
                <AdvertisementSlotBottom variant={lg ? '970x250' : md ? '728x90' : '320x50'} />
              )}
              {(isBetweenMdAndLg && !componentOptions.component_bottom_ads_tablet_disable) && (
                <AdvertisementSlotBottom variant={lg ? '970x250' : md ? '728x90' : '320x50'} />
              )}
              {(mdDown && !componentOptions.component_bottom_ads_mobile_disable) && (
                <AdvertisementSlotBottom variant={lg ? '970x250' : md ? '728x90' : '320x50'} />
              )}
            </>
          )}
        </>
      )}
      
    </Root>
  );
};
